/** *******************************************************************************************************************
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  
  Licensed under the Apache License, Version 2.0 (the "License").
  You may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.                                                                              *
 ******************************************************************************************************************** */
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'aws-northstar/layouts/Container';
import FormRenderer, { componentTypes, validatorTypes } from 'aws-northstar/components/FormRenderer';

export const items = Array.from(Array(8).keys()).map((i) => ({
    label: `Item ${i + 1}`,
    value: `Item ${i + 1}`,
}));

const formSchema = {
    header: 'Time Out Form',
    description: 'Your Sugery incision Checklist - Before skin incision',
    fields: [
        {
    component: componentTypes.SUB_FORM,
    title: 'Surgery RollCall Confirmation Information',
    description: 'This is Sugery roll call information',
    name: 'pincisionIdentityinformation',
    fields: [
        {
            component: componentTypes.CHECKBOX,
            name: 'incisionIdentity',
            label: 'CONFIRM ALL TEAM MEMBERS HAVE INTRODUCED THEMSELVES BY NAME AND ROLE',
            description: 'IDENTITY',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        }
    ]},
        {
    component: componentTypes.SUB_FORM,
    title: 'Incision Verbal Confirmation Checklist',
    description: 'This is a Incision Verbal Confirmation Checklist',
    name: 'incisionVerbalConfirmation',
    fields: [
        ,{
            component: componentTypes.CHECKBOX,
            name: 'siteConfirmationSite',
            label: 'SITE HAS BEEN CONFIRMED',
            description: 'SITE confimration',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'patientConfirmationIncisionCheck',
            label: 'PATIENT CONFIRMATION IS CHECKED',
            description: 'Patient Confirmation',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'patientProcedureConfirmationOnConsent',
            label: 'PATIENT PROCEDURE HAS BEEN CONFIRMED',
            description: 'PROCEDURE',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },
    ]
    },{
    component: componentTypes.SUB_FORM,
    title: 'ANTICIPATED CRITICAL EVENTS',
    description: 'This is a patient critical information Checklist',
    name: 'CriticalInfo',
    fields: [
        {
            component: componentTypes.CHECKBOX,
            name: 'surgeonReview',
            label: 'SURGEON REVIEWS: WHAT ARE THE CRITICAL OR UNEXPECTED STEPS, OPERATIVE DURATION, ANTICIPATED BLOOD LOSS?',
            description: 'SURGEON REVIEWS',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'anaesthesiaReview',
            label: 'ANAESTHESIA TEAM REVIEWS: ARE THERE ANY PATIENT-SPECIFIC CONCERNS?',
            description: 'ANAESTHESIA REVIEWS',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'nursingReview',
            label: 'NURSING TEAM REVIEWS: HAS STERILITY (INCLUDING INDICATOR RESULTS) BEEN CONFIRMED? ARE THERE EQUIPMENT ISSUES OR ANY CONCERNS?',
            description: 'NURSING REVIEWS',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        }
    ],
},
        {
    component: componentTypes.SUB_FORM,
    title: 'ANTIBIOTIC and IMAGING info',
    description: 'This is a patient Antibiotic Information Checklist',
    name: 'AntibioticImagingInfo',
    fields: [
        {
            component: componentTypes.RADIO,
            name: 'AntibioticInfo',
            label: 'HAS ANTIBIOTIC PROPHYLAXIS BEEN GIVEN WITHIN THE LAST 60 MINUTES?',
            options: [
                {
                    label: 'Not Applicable',
                    value: 'NO',
                },
                {
                    label: 'YES',
                    value: 'YES',
                }
            ],
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                },
            ],
        },
        {
            component: componentTypes.RADIO,
            name: 'imagingDisplayed',
            label: 'IS ESSENTIAL IMAGING DISPLAYED?',
            options: [
                {
                    label: 'Not Applicable',
                    value: 'NO',
                },
                {
                    label: 'YES, AND EQUIPMENT/ASSISTANCE AVAILABLE',
                    value: 'YES',
                }
            ],
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                },
            ],
        },
    ],
}]
};


const PreIncisionChecklist: FunctionComponent = () => {
    const history = useHistory();
    return (
        <Container>
            <FormRenderer
                schema={formSchema}
                onSubmit={(data: any) => alert(`Data Submitted: ${JSON.stringify(data)}`)}
                onCancel={() => history.goBack()}
            />
        </Container>
    );
};

export default PreIncisionChecklist;
