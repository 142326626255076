/** *******************************************************************************************************************
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  
  Licensed under the Apache License, Version 2.0 (the "License").
  You may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.                                                                              *
 ******************************************************************************************************************** */
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'aws-northstar/layouts/Container';
import FormRenderer, { componentTypes, validatorTypes } from 'aws-northstar/components/FormRenderer';

export const items = Array.from(Array(8).keys()).map((i) => ({
    label: `Item ${i + 1}`,
    value: `Item ${i + 1}`,
}));

const formSchema = {
    header: 'Sign in Form',
    description: 'Your Sugery Checklist Before induction of anaesthesia',
    fields: [
        {
    component: componentTypes.SUB_FORM,
    title: 'Patient Confirmation Information',
    description: 'This is a patient informtion confirmation form',
    name: 'patientinformation',
    fields: [
        {
            component: componentTypes.CHECKBOX,
            name: 'patientConfirmationIdentity',
            label: 'PATIENT HAS CONFIRMED IDENTITY',
            description: 'IDENTITY',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'patientConfirmationSite',
            label: 'SITE HAS BEEN CONFIRMED WITH PATIENT',
            description: 'SITE',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'patientConfirmationProcedureExplanation',
            label: 'PATIENT PROCEDURE HAS BEEN EXPLAINED',
            description: 'PROCEDURE',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'patientConfirmationOnConsent',
            label: 'PATIENT CONSENT HAS BEEN CONFIRMED',
            description: 'CONSENT',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },
    ]},
        {
    component: componentTypes.SUB_FORM,
    title: 'Patient Pre-Anaesthetic Checklist',
    description: 'This is a patient Anaesthetic  checklistform',
    name: 'patientAnaestheticInformation',
    fields: [
        {
            component: componentTypes.CHECKBOX,
            name: 'SiteMarked',
            label: 'SITE MARKED/NOT APPLICABLE',
            description: 'SITE MARKED/NOT APPLICABLE',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'AnaesthesiaCheckList',
            label: 'ANAESTHESIA SAFETY CHECK COMPLETED',
            description: 'ANAESTHESIA SAFETY CHECK COMPLETED',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'OximeterCheck',
            label: 'PULSE OXIMETER ON PATIENT AND FUNCTIONING',
            description: 'Oximeter check',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },
    ]
    },
        {
    component: componentTypes.SUB_FORM,
    title: 'Patient Allergy and Emergency Checklist',
    description: 'This is a patient Allergy and emergency checklist form',
    name: 'patientAllergyInformation',
    fields: [
        {
            component: componentTypes.RADIO,
            name: 'knownallergy',
            label: 'DOES PATIENT HAVE A KNOWN ALLERGY',
            options: [
                {
                    label: 'No',
                    value: 'NO',
                },
                {
                    label: 'YES',
                    value: 'YES',
                }
            ],
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                },
            ],
        },
        {
            component: componentTypes.RADIO,
            name: 'knownallergy',
            label: 'DOES PATIENT HAVE DIFFICULT AIRWAY/ASPIRATION RISK?',
            options: [
                {
                    label: 'No',
                    value: 'NO',
                },
                {
                    label: 'YES, AND EQUIPMENT/ASSISTANCE AVAILABLE',
                    value: 'YES',
                }
            ],
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                },
            ],
        },
        {
            component: componentTypes.RADIO,
            name: 'bloodlossrisk',
            label: 'RISK OF >500ML BLOOD LOSS (7ML/KG IN CHILDREN)?',
            options: [
                {
                    label: 'No',
                    value: 'NO',
                },
                {
                    label: 'YES, AND ADEQUATE INTRAVENOUS ACCESS AND FLUIDS PLANNED',
                    value: 'YES',
                }
            ],
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                },
            ],
        },
    ],
}]
};

const PreAnaesthesiaChecklist: FunctionComponent = () => {
    const history = useHistory();
    return (
        <Container>
            <FormRenderer
                schema={formSchema}
                onSubmit={(data: any) => alert(`Data Submitted: ${JSON.stringify(data)}`)}
                onCancel={() => history.goBack()}
            />
        </Container>
    );
};

export default PreAnaesthesiaChecklist;
