/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4f319d2a-1ee5-4e82-aeef-5b90f7788d51",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SMpStSEPS",
    "aws_user_pools_web_client_id": "4otc06asm3gartcp28slo7t2u3",
    "oauth": {
        "domain": "createreactapp03dba52b-03dba52b-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.isafechecker.com/",
        "redirectSignOut": "https://www.isafechecker.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
