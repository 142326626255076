/** *******************************************************************************************************************
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  
  Licensed under the Apache License, Version 2.0 (the "License").
  You may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.                                                                              *
 ******************************************************************************************************************** */
import React, { FunctionComponent } from 'react';
import Stack from 'aws-northstar/layouts/Stack';
import Container from 'aws-northstar/layouts/Container';
import ColumnLayout, { Column } from 'aws-northstar/layouts/ColumnLayout';
import ExpandableSection from 'aws-northstar/components/ExpandableSection';
import Link from 'aws-northstar/components/Link';

const Dashboard: FunctionComponent = () => {
    return (
        <Stack>
            <Container title="Checklist Summary">
                <ColumnLayout>
                    <Column key="column1">
                        <ExpandableSection header="Sugical CheckList">
                            NSQHS Standards Icon 6 - Communicating for Safety 
                            The World Health Organization Surgical Safety Checklist has been demonstrated to improve patient safety and is now widely used in Australia as the nationally agreed strategy for surgical safety.
                            </ExpandableSection> 
                            <Link href="/surgicalchecklist" underlineHover={false}>Sugical CheckList</Link>
                    </Column>
                </ColumnLayout>
            </Container>
        </Stack>
    );
};

export default Dashboard;
