/** *******************************************************************************************************************
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  
  Licensed under the Apache License, Version 2.0 (the "License").
  You may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.                                                                              *
 ******************************************************************************************************************** */
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'aws-northstar/layouts/Container';
import FormRenderer, { componentTypes, validatorTypes } from 'aws-northstar/components/FormRenderer';

export const items = Array.from(Array(8).keys()).map((i) => ({
    label: `Item ${i + 1}`,
    value: `Item ${i + 1}`,
}));

const formSchema = {
    header: 'SIGN OUT Form',
    description: 'Your Sugery SIGN OUT Checklist  - Before patient leaves operating room',
    fields: [
        {
    component: componentTypes.SUB_FORM,
    title: 'NURSE VERBALLY CONFIRMS WITH THE TEAM:',
    description: 'NURSE VERBALLY CONFIRMS WITH THE TEAM',
    name: 'nurseverbalConfirmation',
    fields: [
        {
            component: componentTypes.CHECKBOX,
            name: 'procedureName',
            label: 'THE NAME OF THE PROCEDURE RECORDED',
            description: 'THE NAME OF THE PROCEDURE RECORDED',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'instrumentCounts',
            label: 'THAT INSTRUMENT, SPONGE AND NEEDLE COUNTS ARE CORRECT (OR NOT APPLICABLE)',
            description: 'THAT INSTRUMENT, SPONGE AND NEEDLE COUNTS ARE CORRECT (OR NOT APPLICABLE)',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'patientspcimentLabel',
            label: 'HOW THE SPECIMEN IS LABELLED (INCLUDING PATIENT NAME)',
            description: 'HOW THE SPECIMEN IS LABELLED (INCLUDING PATIENT NAME)',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'equipmentProblem',
            label: 'WHETHER THERE ARE ANY EQUIPMENT PROBLEMS TO BE ADDRESSED',
            description: 'WHETHER THERE ARE ANY EQUIPMENT PROBLEMS TO BE ADDRESSED',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },{
            component: componentTypes.CHECKBOX,
            name: 'ecoveryReview',
            label: 'SURGEON, ANAESTHESIA PROFESSIONAL AND NURSE REVIEW THE KEY CONCERNS FOR RECOVERY AND MANAGEMENT OF THIS PATIENT',
            description: 'SURGEON, ANAESTHESIA PROFESSIONAL AND NURSE REVIEW THE KEY CONCERNS FOR RECOVERY AND MANAGEMENT OF THIS PATIENT',
            isRequired: true,
            validate: [
                {
                    type: validatorTypes.REQUIRED,
                }
            ],
        },
    ]},
    ]
};


const PreSurgicalChecklist: FunctionComponent = () => {
    const history = useHistory();
    return (
        <Container>
            <FormRenderer
                schema={formSchema}
                onSubmit={(data: any) => alert(`Data Submitted: ${JSON.stringify(data)}`)}
                onCancel={() => history.goBack()}
            />
        </Container>
    );
};

export default PreSurgicalChecklist;
